<div class="mn bg-background-black py-32 md:py-60">
  <div class="px-16">
    <div class="wrapper">
      <div class="flex items-end justify-between w-full">
        <h2 [innerHTML]="title"></h2>
        <a class="mn__all flex items-center gap-14 underline" [routerLink]="navigation.News">
          <span [innerHTML]="allNewsTitle"></span>
          <mat-icon [svgIcon]="svgIcons.ARROW_BACK" />
        </a>
      </div>

      <div class="mt-30 md:mt-50 flex flex-col justify-between md:flex-row gap-32 md:gap-36 xl:gap-44">
        <div class="mn__main md:w-1/2">
          <app-main-news-card [card]="news[0]" [isMainNews]="true" />
        </div>
        <div class="mn__items md:w-1/2 gap-16 md:gap-30 flex flex-col">
          <div *ngFor="let card of news.slice(1); let i = index" class="mn__item w-full">
            <app-main-news-card *ngIf="i < 3" [card]="card" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
