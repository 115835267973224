import { MainNewsCardComponent } from 'src/app/features/main/components/main-news-card/main-news-card.component'
import { News } from 'src/app/features/main/components/main-news-card/shared/types'
import { NewsCard } from 'src/app/features/news/shared/types'

import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterLink } from '@angular/router'

import { NavigationUrl } from 'src/app/core/constants/navigation'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-main-news',
  standalone: true,
  imports: [NgFor, SvgIconsModule, MatIconModule, RouterLink, MainNewsCardComponent, NgIf],
  templateUrl: './main-news.component.html',
  styleUrl: './main-news.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNewsComponent {
  svgIcons = SvgIcon

  @Input() title!: string
  @Input() allNewsTitle!: string
  @Input() news: NewsCard[] = []

  navigation = NavigationUrl
}
