import { DisplayedFaq } from 'src/app/features/faq/shared/types'

import { NgFor, NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'

import { NavigationUrl } from 'src/app/core/constants/navigation'

@Component({
  selector: 'app-main-faq',
  standalone: true,
  imports: [NgFor, RouterLink, NgIf],
  templateUrl: './main-faq.component.html',
  styleUrl: './main-faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainFaqComponent {
  @Input() title!: string
  @Input() faq: DisplayedFaq[] = []
  navigation = NavigationUrl
}
