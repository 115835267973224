import { TrustServicesCard } from 'src/app/features/main/components/trust-services-card/shared/types'
import { TrustServicesCardComponent } from 'src/app/features/main/components/trust-services-card/trust-services-card.component'

import { NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'

import { NavigationUrl } from 'src/app/core/constants/navigation'
import { HomePageTranslationData } from 'src/app/core/interfaces/page'

@Component({
  selector: 'app-trust-services',
  standalone: true,
  imports: [NgFor, TrustServicesCardComponent],
  templateUrl: './trust-services.component.html',
  styleUrl: './trust-services.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustServicesComponent implements OnInit {
  @Input() data!: HomePageTranslationData

  mockCards: TrustServicesCard[] = [
    {
      title: 'Придбати сертифікат',
      desc: 'Тут ви можете замовити кваліфікований сертифікат.',
      link: NavigationUrl.ServiceBuyStorage,
    },
    {
      title: 'Оновити сертифікат',
      desc: 'Тут ви можете оновити власний сертифікат, якщо термін його дії ще не завершився, а ваші персональні дані не змінювалися.',
      link: NavigationUrl.RefreshCertificate,
    },
    {
      title: 'Пункти обслуговування',
      desc: 'Тут ви можете ознайомитись з переліком пунктів надання послуг, в яких можна придбати сертифікат кваліфікованого електронного підпису.',
      link: NavigationUrl.Contacts,
    },
    {
      title: 'Підписати документ',
      desc: 'Тут ви можете підписати документ за допомогою електронного підпису.',
      link: NavigationUrl.Sign,
    },
    {
      title: 'Перевірити підпис',
      desc: 'Тут ви можете перевірити документ, підписаний КЕП.',
      link: NavigationUrl.CheckSign,
    },
    {
      title: 'Знайти сертифікат',
      desc: 'Тут ви можете ознайомитись з переліком пунктів надання послуг, в яких можна придбати сертифікат кваліфікованого електронного підпису.',
      link: NavigationUrl.SearchCertificates,
    },
  ]

  ngOnInit(): void {
    this.mockCards = this.initCards(this.data).filter((item) => item.title && item.desc && item.link)
  }

  private initCards(content: HomePageTranslationData): TrustServicesCard[] {
    return [
      {
        title: content.firstTrustItemTitle,
        desc: content.firstTrustItemDescription,
        link: content.firstTrustItemLink,
      },
      {
        title: content.secondTrustItemTitle,
        desc: content.secondTrustItemDescription,
        link: content.secondTrustItemLink,
      },
      {
        title: content.thirdTrustItemTitle,
        desc: content.thirdTrustItemDescription,
        link: content.thirdTrustItemLink,
      },
      {
        title: content.fourthTrustItemTitle,
        desc: content.fourthTrustItemDescription,
        link: content.fourthTrustItemLink,
      },
      {
        title: content.fifthTrustItemTitle,
        desc: content.fifthTrustItemDescription,
        link: content.fifthTrustItemLink,
      },
      {
        title: content.sixthTrustItemTitle,
        desc: content.sixthTrustItemDescription,
        link: content.sixthTrustItemLink,
      },
      {
        title: content.seventhTrustItemTitle,
        desc: content.seventhTrustItemDescription,
        link: content.seventhTrustItemLink,
      },
      {
        title: content.eighthTrustItemTitle,
        desc: content.eighthTrustItemDescription,
        link: content.eighthTrustItemLink,
      },
      {
        title: content.ninthTrustItemTitle,
        desc: content.ninthTrustItemDescription,
        link: content.ninthTrustItemLink,
      },
      {
        title: content.tenthTrustItemTitle,
        desc: content.tenthTrustItemDescription,
        link: content.tenthTrustItemLink,
      },
      {
        title: content.eleventhTrustItemTitle,
        desc: content.eleventhTrustItemDescription,
        link: content.eleventhTrustItemLink,
      },
      {
        title: content.twelfthTrustItemTitle,
        desc: content.twelfthTrustItemDescription,
        link: content.twelfthTrustItemLink,
      },
    ]
  }
}
