<div *ngIf="page() as page" class="main">
  <div class="main__hero">
    <div class="main__header px-16 md:px-0">
      <app-header [isHero]="true" [isPortal]="true" />
    </div>

    <div class="main__hero-container flex flex-col justify-center items-center w-full h-full">
      <div class="main__hero-logs flex gap-6">
        <mat-icon [svgIcon]="svgIcons.HERALD" />
        <mat-icon [svgIcon]="svgIcons.PEN_LOGO" />
      </div>
      <div class="main__title mt-20">
        <h1 class="text-center" [innerHTML]="page.translation.data.title"></h1>
      </div>
      <div class="main__hero-btn mt-50">
        <app-btn
          [title]="page?.translation?.data?.mainButtonLabel ?? ''"
          [href]="page?.translation?.data?.mainButtonUrl ?? '/'"
          [hasGradient]="true"
          (clickEvent)="getServices()"
        />
      </div>
    </div>
  </div>

  <div class="px-16">
    <div class="main__services">
      <div class="wrapper">
        <app-trust-services [data]="page.translation.data" />
      </div>
    </div>
  </div>

  <ng-container *ngIf="news() as news">
    <div *ngIf="news.data.length" class="main__news">
      <app-main-news [title]="page.translation.data.newsTitle" [allNewsTitle]="page.translation.data.allNews" [news]="news.data" />
    </div>
  </ng-container>

  <div class="main__faq">
    <app-main-faq [title]="page.translation.data.faqTitle" [faq]="faq()" />
  </div>
</div>
