import { DisplayedFaq } from '@app/features/faq/shared/types'
import { NewsCard } from '@app/features/news/shared/types'
import { HeaderComponent } from 'src/app/common/header/header.component'
import { BtnComponent } from 'src/app/ui-kit/component/btn/btn.component'

import { MainFaqComponent } from './components/main-faq/main-faq.component'
import { MainNewsComponent } from './components/main-news/main-news.component'
import { TrustServicesComponent } from './components/trust-services/trust-services.component'

import { NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, signal } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { ActivatedRoute, Router } from '@angular/router'

import { HomePageTranslationData, Page } from 'src/app/core/interfaces/page'
import { ResponseInterface } from 'src/app/core/interfaces/response'

import { SvgIcon } from 'src/app/shared/svg-icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    HeaderComponent,
    BtnComponent,
    SvgIconsModule,
    MatIconModule,
    TrustServicesComponent,
    MainNewsComponent,
    MainFaqComponent,
    NgIf,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  svgIcons = SvgIcon

  page = signal<Page<HomePageTranslationData>>(this.route.snapshot.data['page'])
  news = signal<ResponseInterface<NewsCard>>(this.route.snapshot.data['news'])
  faq = signal<DisplayedFaq[]>(this.route.snapshot.data['faq'])

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  getServices(): void {
    this.router.navigate(['/order'])
  }
}
